export default {
  menu: [
    {
      label: "Comercializadoras",
      icon: "pi pi-fw pi-user-edit icon-color pt-1",
      to: "/comercializadoras",
    },
    {
      label: "Agencias",
      icon: "pi pi-fw pi-user-edit icon-color pt-1",
      to: "/agencias",
    },
    {
      label: "Juegos",
      icon: "pi pi-fw pi-user-edit icon-color pt-1",
      to: "/juegos",
    },
  ],
};
